<template>
  <div>
    <div class="d-flex align-items-center m-3" v-if="showCropper">
      <vue-cropper
        class="d-flex align-items-center justify-content-center justify-content-sm-center mr-1"
        ref="cropper"
        :guides="true"
        :view-mode="1"
        drag-mode="crop"
        :min-container-width="250"
        :min-container-height="180"
        :max-container-width="1000"
        :max-container-height="500"
        :background="true"
        :rotatable="false"
        :zoomOnWheel="true"
        :cropBoxResizable="true"
        :src="cropperImage"
        alt="Source Image"
        style="max-height: 500px"
      ></vue-cropper>
      <b-button
        size="sm"
        variant="outline-primary"
        class="mr-1"
        @click="cropImage"
        >CROP</b-button
      >
      <b-button
        size="sm"
        variant="outline-danger"
        class="mr-1"
        @click="cancelCrop"
        >CANCEL</b-button
      >
    </div>

    <div v-else class="uploadOuter">
      <p class="mb-1">
        <small class="text-muted mb-2"><b>*file size limit 10mb</b></small
        ><br />
      </p>
      <label for="uploadFile" class="btn btn-warning"
        >Choose files..(max 10mb)</label
      >
      <strong>OR</strong>
      <span class="dragBox">
        Drag and Drop files here..(max 10mb)
        <input type="file" @change="dragNdrop" id="uploadFile" multiple />
      </span>
    </div>

    <b-list-group v-if="showCropper == false">
      <b-list-group-item
        v-for="(objectUrl, fIndex) in filesObjectUrls"
        :key="'images-' + fIndex"
        class="d-flex justify-content-between align-items-center"
      >
        <img
          class="mr-1"
          v-if="checkIfImg(fIndex)"
          :src="objectUrl"
          width="30"
          height="30"
          @click="openFile(fIndex)"
        /><a @click="openFile(fIndex)"
          ><b>
            <u>{{ fileNames[fIndex] }}</u></b
          >
        </a>
        <b-form-input
          class="ml-1 mr-1"
          placeholder="rename file name"
          v-model="fileNames[fIndex]"
        />

        <b-button-group size="sm">
          <!-- <b-button size="sm" variant="outline-primary">edit name</b-button> -->
          <b-button
            size="sm"
            v-if="checkIfImg(fIndex)"
            @click="setCropImage(fIndex)"
            variant="outline-info"
            >crop</b-button
          >
          <b-button
            size="sm"
            @click="removeFile(fIndex)"
            variant="outline-danger"
            >remove</b-button
          >
        </b-button-group>
      </b-list-group-item>
    </b-list-group>

    <!-- <b-row v-if="showCropper == false">
      <b-col
        class="m-1"
        v-for="(objectUrl, fIndex) in filesObjectUrls"
        :key="'images-' + fIndex"
        md="2"
        sm="3"
      >
        <div v-if="checkIfImg(fIndex)">
          <img
            :src="objectUrl"
            width="100"
            height="100"
            @click="openFile(fIndex)"
          />
          <feather-icon
            size="25"
            class="m-1"
            style="cursor: pointer"
            @click="removeFile(fIndex)"
            icon="XCircleIcon"
          />

          <b-button
            class="m-1"
            v-if="checkIfImg(fIndex)"
            size="sm"
            variant="outline-secondary"
            @click="setCropImage(fIndex)"
            >crop</b-button
          >
        </div>
        <b-col v-else md="2" sm="3">
          <div
            class="uploadDivContainer mr-1"
            style="width: 100px; height: 90px; text-align: center; margin: auto"
            v-b-tooltip.hover.right.v-primary
            variant="outline-primary"
            :title="files[fIndex].name"
            @click="openFile(fIndex)"
          >
            <span
              class="d-flex align-items-center justify-content-center justify-content-sm-center m-2"
              ><b>{{ fileNames[fIndex] }}</b>
              <feather-icon size="25" style="cursor: pointer" icon="FileIcon" />
            </span>
          </div>
          <feather-icon
            size="25"
            class="m-1"
            style="cursor: pointer"
            @click="removeFile(fIndex)"
            icon="XCircleIcon"
          />
        </b-col>
        <b-form-input v-model="fileNames[fIndex]" />
      </b-col>
    </b-row> -->

    <div class="m-2 mt-3" v-if="files.length && showCropper == false">
      <b-row>
        <b-col
          class="d-flex align-items-center justify-content-center justify-content-sm-center"
        >
          <b-button block variant="primary" @click="uploadImages"
            >SUBMIT</b-button
          >
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BFormInput,
  VBTooltip,
  BCard,
  BCardText,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButtonGroup,
} from "bootstrap-vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import ToastificationContentVue from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    BRow,
    BCol,
    FeatherIcon,
    BButton,
    BFormInput,
    VueCropper,
    BCard,
    BCardText,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButtonGroup,
    ToastificationContentVue,
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      files: [],
      filesObjectUrls: [],

      fileNames: [],
      cropperImage: null,
      cropImageIndex: null,
      showCropper: false,
    };
  },
  methods: {
    dragNdrop(e) {
      const files = e.target.files;
      const objectUrlArr = [];
      for (const file of files) {
        if (file.size > 10485760) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "max file size limit 10mb",
              icon: "EditIcon",
              variant: "danger",
            },
          });
          break;
        }
        objectUrlArr.push(URL.createObjectURL(file));
        this.files.push(file);
        this.fileNames.push(file.name);
      }

      console.log("hitu", this.fileNames);
      this.filesObjectUrls = this.filesObjectUrls.concat(objectUrlArr);
    },
    removeFile(filesObjectUrlsIndex) {
      this.files.splice(filesObjectUrlsIndex, 1);
      this.filesObjectUrls.splice(filesObjectUrlsIndex, 1);
      this.fileNames.splice(filesObjectUrlsIndex, 1);
    },
    checkIfImg(index) {
      const file = this.files[index];
      if (file.type.includes("image")) {
        return true;
      }
      return false;
    },
    openFile(fIndex) {
      window.open(this.filesObjectUrls[fIndex], "__blank");
    },
    setCropImage(fIndex) {
      this.cropperImage = this.filesObjectUrls[fIndex];
      this.cropImageIndex = fIndex;
      this.showCropper = true;
    },

    cropImage() {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        const oldFile = this.files[this.cropImageIndex];
        const file = new File([blob], oldFile.name, { type: oldFile.type });
        this.files[this.cropImageIndex] = file;
        this.filesObjectUrls[this.cropImageIndex] = URL.createObjectURL(file);
        this.cancelCrop();
      });
    },
    cancelCrop() {
      this.cropperImage = null;
      this.cropImageIndex = null;
      this.showCropper = false;
    },
    uploadImages() {
      const filesWithNames = [];
      this.files.forEach((f, index) => {
        filesWithNames.push([this.fileNames[index], f]);
      });
      this.$emit("input", filesWithNames);
    },
  },
};
</script>

<style>
.uploadOuter {
  text-align: center;
  padding: 20px;
}

.uploadOuter strong {
  padding: 0 10px;
}

.dragBox {
  width: 400px;
  height: 100px;
  margin: 0 auto;
  position: relative;
  text-align: center;
  font-weight: bold;
  line-height: 95px;
  color: #999;
  border: 2px dashed #ccc;
  display: inline-block;
  transition: transform 0.3s;
}

.dragBox input[type="file"] {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  top: 0;
  left: 0;
}

.draging {
  transform: scale(1.1);
}
.file-input {
  padding: 0.25rem;
}
.upload-icon {
  height: px !important;
  width: 60px !important;

  background-color: #edefff;
  /* background-color: #1f307a; */
  border: 1px solid #dae1e7;
  border-radius: 0.357rem;
}
</style>
