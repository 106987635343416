<template>
  <section>
    <b-row>
      <b-col cols="12">
        <b-form-group label="File Type" :hidden="viewOnly">
          <v-select
            id="mc-file-type"
            label="lbl_name"
            placeholder="Select File Type"
            :options="file_types_list"
            :value="file_types"
            @input="onFileTypesInput"
            multiple
            :close-on-select="true"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-col v-for="(ft, index) in file_types" :key="'file_type' + index" cols="12">
          <div>
            <b-alert show variant="primary">
              <h5>
                <b-badge variant="info"> {{ ft.lbl_name }}</b-badge>
              </h5></b-alert
            >
          </div>
          <b-col class="m-1" cols="12">
            <files-container @input="setFiles(ft, $event)" :value="value[ft.lbl_id].files" :viewOnly="viewOnly" />
          </b-col>
        </b-col>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import vSelect from "vue-select";
import { BRow, BCol, BBadge, BFormGroup, BAlert } from "bootstrap-vue";
import { labels } from "@/utils/constants";
import FilesContainer from "./FilesContainer.vue";

export default {
  components: {
    BRow,
    BCol,
    vSelect,
    FilesContainer,
    BBadge,
    BFormGroup,
    BAlert,
  },
  // props: [
  //   "value",
  // ],
  props: {
    value: {
      type: [Object, Array],
      default: () => [],
    },
    viewOnly: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    file_types() {
      const arr = [];
      for (const key in this.value) {
        if (Object.hasOwnProperty.call(this.value, key)) {
          arr.push({
            lbl_id: this.value[key].lbl_id,
            lbl_name: this.value[key].lbl_name,
          });
        }
      }
      return arr;
    },
    file_types_list() {
      return this.$store.state.master.file_type.map((item) => ({
        lbl_id: item.lbl_id,
        lbl_name: item.lbl_name,
      }));
    },
  },
  data() {
    return {};
  },
  methods: {
    setFiles(ft, i) {
      this.value[ft.lbl_id].files = i;
      this.$emit("input", this.value);
    },
    onFileTypesInput(i) {
      const obj = {};
      console.log(this.value);
      for (const ft of i) {
        if (this.value[ft.lbl_id]) {
          obj[ft.lbl_id] = this.value[ft.lbl_id];
        } else {
          obj[ft.lbl_id] = {
            ...ft,
            files: [],
          };
        }
      }
      this.$emit("input", obj);
    },
  },
  beforeMount() {
    this.$store.dispatch("master/setLabelMaster", { key: labels.file_type });
  },
};
</script>

<style></style>
