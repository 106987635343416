export async function validateMovieForm(form) {

    let valid = true;
    let message = 'valid';

    const movie_synopsis = form.movie_synopsis

    if (Array.isArray(movie_synopsis) && movie_synopsis.length) {
        for (const ms of movie_synopsis) {
            if(ms.synopsis_short && ms.synopsis_short.length > 10000) {
                return {
                    valid: false,
                    message: `Short Synopsis Character count cannot be greater than 10000`
                }
            }
            if(ms.synopsis_long && ms.synopsis_long.length > 65000) {
                return {
                    valid: false,
                    message: `Long Synopsis Character count cannot be greater than 65000`
                }
            }
        }
    }

    return {
        valid,
        message
    }
}