// type = save | delete

function showModal(text = "Are You Sure?", type = "save") {
  return this.$bvModal
    .msgBoxConfirm(text, {
      title: "Confirmation",
      size: "sm",
      buttonSize: "sm",
      okVariant: type == "save" ? "success" : "danger",
      cancelVariant: type == "save" ? "outline-success" : "outline-danger",
      okTitle: "YES",
      cancelTitle: "NO",
      footerClass: "p-2",
      hideHeaderClose: true,
      centered: true,
    })
    .then((value) => {
      return value;
    })
    .catch((err) => {
      console.log("error");
      return false;
    });
}

export default showModal;
