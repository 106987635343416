<template>
  <div>
    <b-modal
      title="File Upload Utility"
      ref="imageDropZoneAndCropper"
      id="imageDropZoneAndCropperId"
      size="lg"
      hide-footer
    >
      <files-upload-with-cropper v-if="!uploading" @input="uploadFiles" />
      <div v-else>
        <b-progress
          :value="uploadPercent"
          :max="100"
          show-progress
          animated
        ></b-progress>
      </div>
    </b-modal>
    <a @click="openImageUploadModal" :hidden="viewOnly"
      ><b
        ><u>Choose file <feather-icon icon="UploadIcon" size="15" /></u></b
    ></a>
    <b-row class="mt-1">
      <div
        v-for="(file, file_index) in value"
        :key="'images-' + file_index"
        class="px-1"
      >
        <a class="mt-1" @click="openFile(file)" v-if="checkIfImage(file)">
          {{ file_index + 1 }}-<u>{{
            file && file[0]
              ? file[0]
              : file
              ? getImgSrc(file).split("/").slice(-1).toString()
              : ""
          }}</u>
          <b-img
            :src="getImgSrc(file)"
            v-bind="imgProps"
            rounded="circle"
            class="ml-1"
            alt="Circle image"
          ></b-img
        ></a>
        <a class="mt-1" @click="openFile(file)" v-else>
          {{ file_index + 1 }}-<u>{{
            file && file[0]
              ? file[0]
              : file
              ? getImgSrc(file).split("/").slice(-1).toString()
              : ""
          }}</u>

          <b-img
            :src="filesDefaultImg"
            v-bind="imgProps"
            rounded="circle"
            alt="Circle image"
          ></b-img
        ></a>
        <feather-icon
          size="20"
          s
          style="cursor: pointer; color: red"
          @click="removeFile(file_index)"
          icon="XCircleIcon"
          :hidden="viewOnly"
        />
      </div>
    </b-row>
  </div>
</template>

<script>
import {
  BCol,
  BRow,
  VBTooltip,
  BModal,
  BButton,
  BProgress,
  BCard,
  BCardText,
  BImg,
} from "bootstrap-vue";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import FilesUploadWithCropper from "./FilesUploadWithCropper.vue";
import { uploadMultiFiles } from "@/utils/helpers";
import Ripple from "vue-ripple-directive";

export default {
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  components: {
    BCol,
    BRow,
    FeatherIcon,
    VBTooltip,
    BModal,
    FilesUploadWithCropper,
    BButton,
    BProgress,
    BCard,
    BCardText,
    BImg,
  },

  data() {
    return {
      imgProps: {
        blank: false,
        blankColor: "#777",
        width: 25,
        height: 25,
        class: "m1",
      },
      uploading: false,
      uploadPercent: 0,
      filesDefaultImg: require("@/assets/images/pages/files-documents.png"),
    };
  },

  props: {
    value: {
      type: [Object, Array],
      default: () => [],
    },
    show_only: {
      type: Boolean,
      default: false,
    },
    viewOnly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    files_url() {
      return process.env.VUE_APP_FILESURL;
    },
  },
  methods: {
    getFileName(file) {
      if (Array.isArray(file)) {
        return file[0];
      } else {
        return "";
      }
    },
    getImgSrc(file) {
      if (Array.isArray(file)) {
        if (file[1]) {
          return this.files_url + file[1];
        }
      } else {
        return this.files_url + file;
      }
    },

    openImageUploadModal() {
      this.$refs["imageDropZoneAndCropper"].show();
    },
    emitInput(files) {
      this.$emit("input", files);
    },

    onUploadProgress(progressEvent) {
      this.uploadPercent = parseInt(
        Math.round((progressEvent.loaded / progressEvent.total) * 100)
      );
    },

    async uploadFiles(files) {
      this.uploading = true;
      try {
        const filesArr = files.map((f) => f[1]);

        const uploadPaths = await uploadMultiFiles(
          filesArr,
          this.onUploadProgress
        );

        if (uploadPaths && Array.isArray(uploadPaths) && uploadPaths.length) {
          let filesArr =
            this.value && Array.isArray(this.value) ? this.value : [];

          const newArr = uploadPaths.map((uploadPath, index) => [
            files[index][0],
            uploadPath,
          ]);

          filesArr = filesArr.concat(newArr);

          this.emitInput(filesArr);
          this.$refs["imageDropZoneAndCropper"].hide();
        }
      } catch (err) {
        console.error("Error in uploadFiles ", err);
      } finally {
        this.uploading = false;
        this.uploadPercent = 0;
      }
    },

    removeFile(file_index) {
      const files = this.value;
      files.splice(file_index, 1);
      this.emitInput(files);
    },

    openFile(file) {
      if (Array.isArray(file)) {
        if (file[1]) {
          window.open(this.files_url + file[1], "__blank");
        }
      } else {
        window.open(this.files_url + file, "__blank");
      }
    },

    checkIfImage(file) {
      if (!file) return false;

      let uploadPath = "";
      if (Array.isArray(file)) {
        uploadPath = file[1];
      } else {
        uploadPath = file;
      }

      if (
        typeof uploadPath === "string" &&
        uploadPath.match(/\.(png|jpg|jpeg|webp)$/)
      ) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style>
.uploadDivContainer {
  border: solid 2px orange;
  border: 5px solid #f3f2f7;
  cursor: pointer;
  width: 100px;
  aspect-ratio: auto 100 / 100;
  /* height: 100px; */
  text-align: center;
}
.upload-icon {
  height: 45px !important;
  width: 60px !important;
  padding: 0.25rem;
  background-color: #edefff;
  /* background-color: #1f307a; */
  border: 1px solid #dae1e7;
  border-radius: 0.357rem;
}
.f-10 {
  font-size: 8px;
  font-weight: bolder;
}
.file-name {
  /* ----for showing full name---- */
  height: 1em;
  line-height: 1em;
  overflow: hidden;
  /* ----for showing dots---- */
  /* display: inline-block;
  width: 90%;
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis; */
}
img {
  /* height: 200px !important; */
  /* width: 150px !important; */
}
</style>
