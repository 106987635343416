<template>
  <!-- new -->
  <b-card>
    <!-- <div class="d-flex align-items-center">
      <div class="py-2">
        <button
          @click="generatePDF('movieDetails', 'Movie Details')"
          :class="'btn btn-primary'"
        >
          Download PDF
        </button>
      </div>
      <div class="log-buttons" v-if="logsExist">
        <div>
          <button
            @click="getPreviousLog"
            :class="'btn btn-primary ' + (disablePrevButton && 'd-none')"
          >
            Back
          </button>
        </div>
        <div>
          <button
            @click="getNextLog"
            :class="'btn btn-primary ' + (disableNextButton && 'd-none')"
          >
            Next
          </button>
        </div>
      </div>
    </div> -->
    <b-card id="movieDetails">
      <!-- <b-row class="bg-color">
        <b-col md="12">
          <h5>
            <b-badge variant="primary"
              >Movie Unique ID :
              {{
                !movie_id && !isNaN(movie_id)
                  ? newMovieUniqueId
                  : form.movie_information.unique
              }}</b-badge
            >
          </h5>
        </b-col>
      </b-row> -->
      <!-- Movie Information -->
      <div>
        <h4 class="details-heading mt-3 mb-2">Movie Information</h4>
      </div>
      <div class="container-fluid">
        <div class="mb-1">
          <div class="pr-md-5 d-flex">
            <p class="details-subheading">Movie Unique ID:</p>
            <p>
              {{ !movie_id && !isNaN(movie_id) ? newMovieUniqueId : form.movie_information.unique }}
            </p>
          </div>
        </div>

        <div v-if="form.movie_information.title" class="mb-1">
          <div class="pr-md-5 d-flex">
            <p class="details-subheading">Movie Title:</p>
            <p>{{ form.movie_information.title }}</p>
          </div>
        </div>

        <div v-if="form.movie_information.international_title" class="mb-1">
          <div class="pr-md-5 d-flex">
            <p class="details-subheading">International Movie Title:</p>
            <p>{{ form.movie_information.international_title }}</p>
          </div>
        </div>

        <div v-if="getNamesById(form.movie_information.type, 'type')" class="mb-1">
          <div class="pr-md-5 d-flex">
            <p class="details-subheading">Type:</p>
            <p>{{ getNamesById(form.movie_information.type, "type") }}</p>
          </div>
        </div>

        <div v-if="form.movie_information.year" class="mb-1">
          <div class="pr-md-5 d-flex">
            <p class="details-subheading">Year:</p>
            <p>{{ form.movie_information.year }}</p>
          </div>
        </div>

        <div v-if="getNamesById(form.movie_information.original_language, 'language')" class="mb-1">
          <div class="pr-md-5 d-flex">
            <p class="details-subheading">Original Languages:</p>
            {{ getNamesById(form.movie_information.original_language, "language") }}
          </div>
        </div>
      </div>

      <div class="container-fluid mt-2">
        <hr />
      </div>

      <!-- Content Rating and Distribution -->
      <div>
        <h4 class="details-heading mb-2 mt-3">Content Rating and Distribution</h4>
        <div class="row container col-12">
          <div class="col-12 mb-2" v-if="form.movie_information.trailer_link">
            <p class="details-subheading">Trailer Link:</p>
            <p>{{ form.movie_information.trailer_link }}</p>
          </div>
          <div class="col-12 mb-2" v-if="form.movie_information.weblink">
            <p class="details-subheading">Weblink/IMDB:</p>
            <p>{{ form.movie_information.weblink }}</p>
          </div>
          <div class="row container col-12">
            <div class="col-12 col-md-3 mb-2" v-if="getNamesById(form.movie_information.age, 'age')">
              <p class="details-subheading">Age/Rating:</p>
              <p>{{ getNamesById(form.movie_information.age, "age") }}</p>
            </div>
            <div class="col-12 col-md-2 mb-2" v-if="getNamesById(form.movie_information.image_format, 'image_format')">
              <p class="details-subheading">Image Format:</p>
              <p>
                {{ getNamesById(form.movie_information.image_format, "image_format") }}
              </p>
            </div>
            <div class="col-12 col-md-2 mb-2" v-if="getNamesById(form.movie_information.sound_format, 'sound_format')">
              <p class="details-subheading">Sound Format:</p>
              <p>
                {{ getNamesById(form.movie_information.sound_format, "sound_format") }}
              </p>
            </div>
            <div class="col-12 col-md-2 mb-2" v-if="form.movie_information.suisan_number">
              <p class="details-subheading">SUISA Number:</p>
              <p>{{ form.movie_information.suisan_number }}</p>
            </div>
            <div class="col-12 col-md-2 mb-2" v-if="form.movie_information.isan_number">
              <p class="details-subheading">ISAN Number:</p>
              <p>{{ form.movie_information.isan_number }}</p>
            </div>
          </div>
        </div>
        <div class="col mb-2" v-if="getNamesById(form.movie_information.avaliable_languages, 'language')">
          <p class="details-subheading">Available Languages:</p>
          <p>
            {{ getNamesById(form.movie_information.avaliable_languages, "language") }}
          </p>
        </div>
      </div>
      <div class="container-fluid">
        <hr />
      </div>

      <!-- Production Details -->
      <div class="mt-3">
        <div>
          <h4 class="details-heading mb-2 mt-1">Production Details</h4>
          <div class="row container col-12">
            <div class="col-12 mb-2 d-flex" v-if="getNamesById(form.movie_information.director, 'director')">
              <p class="details-subheading">Directors:</p>
              <p>
                {{ getNamesById(form.movie_information.director, "director") }}
              </p>
            </div>

            <div class="col-12 mb-2 d-flex" v-if="getNamesById(form.movie_information.cast, 'cast')">
              <p class="details-subheading">Cast:</p>
              <p>{{ getNamesById(form.movie_information.cast, "cast") }}</p>
            </div>
            <div class="col-12 mb-2 d-flex" v-if="getNamesById(form.movie_information.genres, 'genre')">
              <p class="details-subheading">Genre(s):</p>
              <p>{{ getNamesById(form.movie_information.genres, "genre") }}</p>
            </div>
            <div class="col-12 mb-2 d-flex" v-if="getNamesById(form.movie_information.country, 'country')">
              <p class="details-subheading">Countries:</p>
              <p>
                {{ getNamesById(form.movie_information.country, "country") }}
              </p>
            </div>
            <div class="col-12 mb-2 d-flex" v-if="form.movie_information.duration">
              <p class="details-subheading">Duration:</p>
              <p>{{ form.movie_information.duration }} mins</p>
            </div>
          </div>
        </div>
      </div>

      <div class="container-fluid">
        <hr />
      </div>

      <div class="container-fluid mt-3">
        <h4 class="details-heading mb-2 mt-1">Additional Movie Information Per Language</h4>

        <div class="row container col-12">
          <table class="border rounded-lg">
            <thead class="border">
              <tr>
                <th class="border p-1">Sr No.</th>
                <th class="border p-1">Language Name</th>
                <th class="border p-1">Title</th>
                <th class="border p-1">Trailer Link</th>
                <th class="border p-1" style="width: 500px">Files</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(lang, lang_index) in form.additional_langs_info" :key="'additional_lang_' + lang_index">
                <td class="text-center border p-1">{{ lang_index + 1 }}</td>
                <td class="text-center border p-1">{{ lang.langauge_name }}</td>
                <td class="text-center border p-1">{{ lang.title }}</td>
                <td class="text-center border p-1">{{ lang.trailer_link }}</td>
                <td class="text-center border p-1">
                  <div
                    v-for="(lang_file, lang_files_index) in lang.files"
                    :key="'lang_files' + lang_files_index"
                    class="mb-2"
                  >
                    <p class="p-0 m-0">
                      <b>{{ lang_file.lbl_name }}:</b>
                    </p>
                    <files-container :value="lang_file.files" :viewOnly="true" />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="container-fluid mt-1">
        <hr />
      </div>

      <!-- Movie Release Rights -->
      <div class="container-fluid mt-3">
        <h4 class="details-heading mb-2 mt-1">Release Date</h4>
        <table class="border rounded-lg">
          <thead class="border">
            <tr>
              <th class="border p-1">Sr No.</th>
              <th class="border p-1">Rights</th>
              <th class="border p-1">Territory</th>
              <th class="border p-1">Language</th>
              <th class="border p-1">Date</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(release, release_index) in form.movie_release" :key="'movie_release_' + release_index">
              <td class="text-center border p-1">{{ release_index + 1 }}</td>
              <td class="text-center border p-1">
                {{ getTreeNamesById(release.right, "rights_tree") }}
              </td>
              <td class="text-center border p-1">
                {{ getTreeNamesById(release.territory, "territory_tree") }}
              </td>
              <td class="text-center border p-1">
                {{ getNamesById(release.language, "language") }}
              </td>
              <td class="text-center border p-1">{{ release.date }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="container-fluid mt-1">
        <hr />
      </div>

      <div class="mt-3">
        <div class="container-fluid">
          <h4 class="details-heading text-left mb-1 mt-1">Synopsis</h4>
          <table class="border rounded-lg">
            <thead class="border">
              <tr>
                <th class="border p-1">Sr No.</th>
                <th class="border p-1">Language</th>
                <th class="border p-1">Press Quotes</th>
                <th class="border p-1">Short Synopsis</th>
                <th class="border p-1">Long Synopsis</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(synopsis, synopsis_index) in form.movie_synopsis" :key="'movie_synopsis_' + synopsis_index">
                <td class="text-center border p-1">{{ synopsis_index + 1 }}</td>
                <td class="text-center border p-1">
                  {{ getNamesById(synopsis.language, "language") }}
                </td>
                <td class="text-center border p-1">
                  {{ synopsis.press_quotes }}
                </td>
                <td class="text-center border p-1">
                  {{ synopsis.synopsis_short }}
                </td>
                <td class="text-center border p-1">
                  {{ synopsis.synopsis_long }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="container-fluid mt-1">
        <hr />
      </div>

      <!-- product informataion -->
      <div class="container-fluid mt-3">
        <h4 class="details-heading mb-1 mt-1">Products</h4>
        <table class="border rounded-lg">
          <thead class="border">
            <tr>
              <th class="border p-1">Sr No.</th>
              <th class="border p-1">Product Information</th>
              <th class="border p-1">Audio/Vision Information</th>
              <th class="border p-1">Manufacture/Studio Information</th>
              <th class="border p-1">Marketing/Studio Information</th>
              <th class="border p-1">Genre/Purchasing Information</th>
              <th class="border p-1" style="width: 275px">Artwork</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(product, product_index) in form.movie_product" :key="'movie_product_' + product_index">
              <td class="border p-1">{{ product_index + 1 }}</td>
              <td class="border p-1">
                <div class="mb-1">
                  <p class="details-subheading block">Product Title</p>
                  <p>{{ product.title }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Media Type:</p>
                  <p>{{ getNamesById(product.media_type, "media_type") }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Product Status:</p>
                  <p>{{ product.pstatus }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Catalog Number:</p>
                  <p>{{ product.catalog_number }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">EAN:</p>
                  <p>{{ product.ean }}</p>
                </div>
              </td>
              <td class="border p-1">
                <div class="mb-1">
                  <p class="details-subheading">Language Tracks:</p>
                  <p>{{ getNamesById(product.language_tracks, "language") }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Running Time:</p>
                  <p>{{ product.runtime }} mins</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Pricing Code:</p>
                  <p>{{ getNamesById(product.price_code, "pricing_code") }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Aspect Ratio:</p>
                  <p>{{ product.aspect_ratio }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Number Of Disks:</p>
                  <p>{{ product.number_of_disc }}</p>
                </div>
              </td>
              <td class="border p-1">
                <div class="mb-1">
                  <p class="details-subheading">Label:</p>
                  <p>{{ getNamesById(product.label, "label") }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Authorizing Studio:</p>
                  <p>{{ product.product_studio }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Product Duplicator:</p>
                  <p>{{ product.product_duplicator }}</p>
                </div>
              </td>
              <td class="border p-1">
                <div class="mb-1">
                  <p class="details-subheading">Key Marketing Points:</p>
                  <p>{{ product.marketing_points }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Rating:</p>
                  <p>{{ getNamesById(product.rating, "age") }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">SUISSA:</p>
                  <p>{{ product.suisa }}</p>
                </div>
              </td>
              <td class="border p-1">
                <div class="mb-1">
                  <p class="details-subheading">Genre:</p>
                  <p>{{ getNamesById(product.genre, "genre") }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Product Description:</p>
                  <p>{{ product.product_description }}</p>
                </div>
                <div class="mb-1">
                  <p class="details-subheading">Product Purchased From:</p>
                  {{ product.purchased_from }}
                </div>
              </td>
              <td class="border p-1">
                <div>
                  <files-container :value="product.product_art" :viewOnly="true" />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="container-fluid mt-1">
        <hr />
      </div>

      <!-- Distributors -->

      <div class="container-fluid mt-3">
        <h4 class="details-heading mb-2 mt-1">Distribution</h4>
        <table class="border rounded-lg">
          <thead class="border">
            <tr>
              <th class="border p-1">Sr No.</th>
              <th class="border p-1">Name</th>
              <th class="border p-1">Country</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(dis, dis_index) in form.movie_distributors" :key="'dis-' + dis_index">
              <td class="text-center border p-1">{{ dis_index + 1 }}</td>
              <td class="text-center border p-1">
                <p>{{ dis.distributor_name }}</p>
              </td>
              <td class="text-center border p-1">
                <p>{{ dis.country_name }}</p>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- ==================== FILE UPLOADS ==================== -->
      <div class="col">
        <b-row>
          <b-col cols="12">
            <app-collapse class="collapse-group" accordion type="border">
              <!-- Files -->
              <app-collapse-item title="Files">
                <files-container :value="form.movie_information.movie_files" :viewOnly="true" />
                <all-files-upload v-model="form.movie_information.movie_files" :viewOnly="true" />
              </app-collapse-item>
            </app-collapse>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </b-card>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
  BCard,
  BFormDatepicker,
  BFormTextarea,
  BFormSelect,
  BFormSelectOption,
  BImg,
  BBadge,
  BContainer,
} from "bootstrap-vue";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";
import Treeselect from "@riophae/vue-treeselect";
import Ripple from "vue-ripple-directive";
import * as MovieServices from "@/apiServices/MovieServices";
import vSelect from "vue-select";
import { labels } from "@/utils/constants";
import {
  convertToIntArr,
  convertToStrArr,
  // uploadImages,
  getNamesById,
  getTreeNamesById,
  generatePDF,
} from "@/utils/helpers";
import flatPickr from "vue-flatpickr-component";
import FeatherIcon from "@core/components/feather-icon/FeatherIcon.vue";
import ToastificationContentVue from "@/@core/components/toastification/ToastificationContent.vue";
import { addData } from "@/apiServices/MastersServices";
import FilesContainer from "@/views/components/FilesUpload/FilesContainer.vue";
import AllFilesUpload from "@/views/components/FilesUpload/AllFilesUpload.vue";

export default {
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BCard,
    BFormTextarea,
    AppCollapse,
    AppCollapseItem,
    BFormDatepicker,
    vSelect,
    Treeselect,
    flatPickr,
    FeatherIcon,
    BFormSelect,
    BFormSelectOption,
    BImg,
    BBadge,
    FilesContainer,
    BContainer,
    AllFilesUpload,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isViewOnly: true,
      newMovieUniqueId: null,
      movie_id: null,
      logsExist: false,
      current_log: 0,
      log_count: 0,
      disableNextButton: true,
      disablePrevButton: false,
      form: {
        draft: false,
        movie_information: {
          title: "",
          international_title: "",
          type: [],
          year: "",
          original_language: [],
          director: [],
          cast: [],
          genres: [],
          country: [],
          duration: "",
          age: [],
          suisan_number: "",
          image_format: [],
          sound_format: [],
          isan_number: "",
          unique: "",
          weblink: "",
          trailer_link: "",
          avaliable_languages: [],
          avaliable_subTitle: [],
          original_art_work: [],
          movie_stills: [],
          music_que_sheet: [],
          subtitle_files: [],
          audio_files: [],
          various_files: [],
          social_media: [],
          press_kit: [],
          status: null,
        },
        additional_langs: [],
        additional_langs_info: [],
        /*
        {
          language_id: '',
          langauge_name: '',
          title: '',
          trailer_link: '',
          artwork: ';,
          pictures: '',
          trailer: '',
          social_media: '',
          audio_files: '',
          subtitle_files: ''
          school_dossier: ''
        }
        */
        movie_release: [
          {
            date: "",
            language: [],
            right: [],
            territory: [],
          },
        ],
        movie_synopsis: [
          {
            language: "",
            synopsis_short: "",
            synopsis_long: "",
            press_quotes: "",
          },
        ],
        movie_product: [
          {
            title: "",
            media_type: [],
            pstatus: "",
            catalog_number: "",
            ean: "",
            language_tracks: [],
            subtitle: [],
            runtime: "",
            price_code: "",
            aspect_ratio: [],
            number_of_disc: "",
            label: [],
            purchased_from: "",
            product_studio: "",
            product_duplicator: "",
            marketing_points: "",
            rating: [],
            suisa: "",
            genre: [],
            product_description: "",
          },
        ],
        movie_distributors: [
          {
            name: "",
            country: "",
          },
        ],
      },
    };
  },
  computed: {
    files_url() {
      return process.env.VUE_APP_FILESURL;
    },
  },
  methods: {
    getNamesById,
    getTreeNamesById,

    getNextLog() {
      if (this.current_log == this.log_count) {
        this.current_log = this.log_count;
      } else {
        this.current_log++;
      }
      this.getMovieDetails();
    },

    getPreviousLog() {
      if (this.current_log == 0) {
        this.current_log = 0;
      } else {
        this.current_log--;
      }
      this.getMovieDetails();
    },
    // getNamesById(id_array, item_name) {
    //   let item_objects = this.$store.state.master[item_name].filter((item) =>
    //     id_array.includes(item.lbl_id)
    //   );

    //   let item_names = item_objects.map((item) => item.lbl_name).join(", ");

    //   return item_names;
    // },

    // getTreeNamesById(id_array, item_name) {
    //   let item_objects = this.$store.state.master[item_name].filter((item) =>
    //     id_array.includes(item.id)
    //   );

    //   let item_names = item_objects.map((item) => item.label).join(", ");

    //   return item_names;
    // },

    // getMultipleFiles(files) {
    //   const lbl_ids = Object.keys(files);
    //   const files_arr = [];
    //   for (let lbl_id of lbl_ids) {
    //     for (let file of files[lbl_id].files[0]) {
    //       if (file) files_arr.push(file);
    //     }
    //   }
    //   return [files_arr];
    // },

    async addLabel(cat, text) {
      const response = await addData(cat, { lbl_name: text });
      if (!response.data.data.lbl_id) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: response.data.message || "Cast Not added!",
            icon: "EditIcon",
            variant: "failure",
          },
        });
        return;
      }

      this.$toast({
        component: ToastificationContentVue,
        props: {
          title: response.data.message || "Success",
          icon: "EditIcon",
          variant: "success",
        },
      });

      this.$store.dispatch("master/setLabelMaster", { key: cat });
    },
    generatePDF,

    onAdditionalLangInput(input) {
      const additional_langs_info_arr = [];

      for (const lbl_id of input) {
        let obj = undefined;

        for (const lang_info of this.form.additional_langs_info) {
          if (lang_info.language_id === lbl_id) {
            obj = lang_info;
          }
        }

        if (!obj) {
          const langauge_lbl = this.$store.state.master.language.find((item) => {
            if (item.lbl_id === lbl_id) return true;
            return false;
          });

          obj = {
            language_id: lbl_id,
            langauge_name: langauge_lbl ? langauge_lbl.lbl_name : "",
            title: "",
            trailer_link: "",
            artwork: "",
            pictures: "",
            trailer: "",
            social_media: "",
            audio_files: "",
            subtitle_files: "",
            school_dossier: "",
            press_kit: "",
          };
        }

        additional_langs_info_arr.push(obj);
      }

      this.form.additional_langs_info = additional_langs_info_arr;
    },

    addRelease() {
      this.form.movie_release.push({
        date: "",
        language: [],
        right: [],
        territory: [],
      });
    },

    removeRelease(index) {
      this.form.movie_release.splice(index, 1);
    },

    addSynopsis() {
      this.form.movie_synopsis.push({
        language: [],
        synopsis_short: "",
        synopsis_long: "",
        press_quotes: "",
      });
    },

    removeSynopsis(index) {
      this.form.movie_synopsis.splice(index, 1);
    },

    addProduct() {
      this.form.movie_product.push({
        title: "",
        media_type: [],
        pstatus: "",
        catalog_number: "",
        ean: "",
        language_tracks: [],
        subtitle: [],
        runtime: "",
        price_code: "",
        aspect_ratio: [],
        number_of_disc: "",
        label: [],
        purchased_from: "",
        product_studio: "",
        product_duplicator: "",
        marketing_points: "",
        rating: [],
        suisa: "",
        genre: [],
        product_description: "",
      });
    },

    addDistributor() {
      this.form.movie_distributors.push({
        name: "",
        country: "",
      });
    },

    removeDistributor(index) {
      this.form.movie_distributors.splice(index, 1);
    },

    removeProduct(index) {
      this.form.movie_product.splice(index, 1);
    },

    // async uploadOriginalArtworkImages(e) {
    //   try {
    //     const names = await uploadImages(e);

    //     if (names && names.length) {
    //       names.forEach((img) => {
    //         this.form.movie_information.original_art_work.push(img);
    //       });
    //     }
    //   } catch (err) {
    //     console.log("Error in uploadImage ", err);
    //   }
    // },
    // removeOriginalArtWorkImage(index) {
    //   this.form.movie_information.original_art_work.splice(index, 1);
    // },

    // async uploadProdArtImages(e, product_index) {
    //   try {
    //     const names = await uploadImages(e);

    //     if (names && names.length) {
    //       names.forEach((img) => {
    //         this.form.movie_product[product_index].product_art.push(img);
    //       });
    //     }
    //   } catch (err) {
    //     console.log("Error in uploadImage ", err);
    //   }
    // },

    // removeProdArtImage(prod_index, img_index) {
    //   this.form.movie_product[prod_index].product_art.splice(img_index, 1);
    // },
    // openImage(img_url) {
    //   window.open(img_url, "__blank");
    // },

    async GetMovieUniqueId() {
      try {
        const response = await MovieServices.getNewMovieUnique();
        if (response.data.status) {
          if (response.data.data && response.data.data.unique) {
            this.newMovieUniqueId = parseInt(response.data.data.unique) + 1;
          }
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },

    async getMovieResponse(movie_id) {
      this.movie = await MovieServices.getMovieLogs(movie_id);
      if (this.movie.data.data[0]) {
        this.logsExist = true;
        this.log_count = this.movie.data.data.length - 1;
        this.current_log = this.movie.data.data.length - 1;
        this.getMovieDetails();
      } else {
        this.movie = await MovieServices.getSingleMovie(movie_id);
        this.getMovieDetails();
      }
    },

    async getMovieDetails() {
      try {
        let response;
        this.current_log === 0 ? (this.disablePrevButton = true) : (this.disablePrevButton = false);
        this.current_log === this.log_count ? (this.disableNextButton = true) : (this.disableNextButton = false);

        if (Array.isArray(this.movie.data.data)) {
          response = JSON.parse(this.movie.data.data[this.current_log].json_text);
        } else {
          response = this.movie.data.data;
        }

        if (response) {
          const {
            movie_information,
            // movie_assets,
            additional_langs,
            additional_langs_info,
            movie_distributors,
            movie_product,
            movie_release,
            movie_synopsis,
          } = response;

          this.form.movie_information = {
            ...movie_information,
            type: convertToIntArr(movie_information.type),
            original_language: convertToIntArr(movie_information.original_language),
            director: convertToIntArr(movie_information.director),
            cast: convertToIntArr(movie_information.cast),
            genres: convertToIntArr(movie_information.genres),
            country: convertToIntArr(movie_information.country),
            age: convertToIntArr(movie_information.age),
            image_format: convertToIntArr(movie_information.image_format),
            sound_format: convertToIntArr(movie_information.sound_format),
            avaliable_languages: convertToIntArr(movie_information.avaliable_languages),
            avaliable_subTitle: convertToIntArr(movie_information.avaliable_subTitle),
            original_art_work: convertToStrArr(movie_information.original_art_work),
            movie_stills: convertToStrArr(movie_information.movie_stills),
            music_que_sheet: convertToStrArr(movie_information.music_que_sheet),
            subtitle_files: convertToStrArr(movie_information.subtitle_files),
            audio_files: convertToStrArr(movie_information.audio_files),
            various_files: convertToStrArr(movie_information.various_files),
            social_media: convertToStrArr(movie_information.social_media),
            press_kit: convertToStrArr(movie_information.press_kit),
            movie_files: movie_information.movie_files,
          };

          if (movie_release && movie_release.length) {
            this.form.movie_release = movie_release.map((release) => {
              return {
                date: release.date,
                language: convertToIntArr(release.language),
                right: convertToIntArr(release.right),
                territory: convertToIntArr(release.terriorty),
              };
            });
          }

          if (movie_synopsis && movie_synopsis.length) {
            this.form.movie_synopsis = movie_synopsis.map((synopsis) => {
              return {
                ...synopsis,
                language: convertToIntArr(synopsis.language),
              };
            });
          }

          if (movie_product && movie_product.length) {
            this.form.movie_product = movie_product.map((product) => {
              return {
                ...product,
                aspect_ratio: convertToIntArr(product.aspect_ratio),
                label: convertToIntArr(product.label),
                language_tracks: convertToIntArr(product.language_tracks),
                media_type: convertToIntArr(product.media_type),
                rating: convertToIntArr(product.rating),
                subtitle: convertToIntArr(product.subtitle),
                price_code: convertToIntArr(product.price_code),
                genre: convertToIntArr(product.genre),
                product_art: convertToStrArr(product.product_art),
              };
            });
          }

          if (movie_distributors && movie_distributors.length) {
            this.form.movie_distributors = movie_distributors.map((dis) => {
              return {
                ...dis,
                name: convertToIntArr(dis.name),
                place: convertToIntArr(dis.place),
              };
            });
          }

          if (additional_langs_info && additional_langs_info.length) {
            this.form.additional_langs_info = additional_langs_info.map((lang) => {
              return {
                ...lang,
                artwork: convertToStrArr(lang.artwork),
                pictures: convertToStrArr(lang.pictures),
                trailer: convertToStrArr(lang.trailer),
                social_media: convertToStrArr(lang.social_media),
                audio_files: convertToStrArr(lang.audio_files),
                subtitle_files: convertToStrArr(lang.subtitle_files),
                school_dossier: convertToStrArr(lang.school_dossier),
                press_kit: convertToStrArr(lang.press_kit),
              };
            });
          }

          if (additional_langs && additional_langs.length) {
            this.form.additional_langs = additional_langs;
          }
        }
      } catch (err) {
        console.log("Error in getting  ", err);
      }
    },

    saveMovieAsDraft() {
      this.form.draft = true;
      this.$nextTick(() => {
        this.saveMovie();
      });
    },

    async saveMovie() {
      try {
        if (!this.form.movie_information.title) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: "Please Enter Title",
              icon: "EditIcon",
              variant: "warning",
            },
          });
          return;
        }

        const response = await MovieServices.saveMovie({
          movieID: this.movie_id,
          newMovieUniqueId: this.newMovieUniqueId,
          ...this.form,
        });

        if (response.data.status) {
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Movie Saved Successfully",
              icon: "EditIcon",
              variant: "success",
            },
          });
          this.$router.go(-1);
        } else {
          if (response.data.data && response.data.data.unique) {
            this.newMovieUniqueId = response.data.data.unique;
          }
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: response.data.message || "Movie Not Saved",
              icon: "EditIcon",
              variant: "failure",
            },
          });
        }
      } catch (err) {
        console.log("Error in saveMovie ", err);
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: "Server Error",
            icon: "EditIcon",
            variant: "failure",
          },
        });
      }
    },

    resetForm() {
      this.form = {
        draft: false,
        movie_information: {
          title: "",
          international_title: "",
          type: [],
          year: "",
          original_language: [],
          director: [],
          cast: [],
          genres: [],
          country: [],
          duration: "",
          age: [],
          suisan_number: "",
          image_format: [],
          sound_format: [],
          isan_number: "",
          unique: "",
          weblink: "",
          trailer_link: "",
          avaliable_languages: [],
          avaliable_subTitle: [],
          original_art_work: [],
          movie_stills: [],
          music_que_sheet: [],
          status: null,
        },
        additional_langs: [],
        additional_langs_info: [],
        /*
        {
          language_id: '',
          langauge_name: '',
          title: '',
          trailer_link: '',
          artwork: ';,
          pictures: '',
          trailer: '',
          social_media: '',
          audio_files: '',
          subtitle_files: ''
          school_dossier: ''
        }
        */
        movie_release: [
          {
            date: "",
            language: [],
            right: [],
            territory: [],
          },
        ],
        movie_synopsis: [
          {
            language: "",
            synopsis_short: "",
            synopsis_long: "",
            press_quotes: "",
          },
        ],
        movie_product: [
          {
            title: "",
            media_type: [],
            pstatus: "",
            catalog_number: "",
            ean: "",
            language_tracks: [],
            subtitle: [],
            runtime: "",
            price_code: "",
            aspect_ratio: [],
            number_of_disc: "",
            label: [],
            purchased_from: "",
            product_studio: "",
            product_duplicator: "",
            marketing_points: "",
            rating: [],
            suisa: "",
            genre: [],
            product_description: "",
          },
        ],
        movie_distributors: [
          {
            name: "",
            country: "",
          },
        ],
      };
    },
  },
  beforeMount() {
    const movie_id = this.$route.params.movie_id;
    this.GetMovieUniqueId();
    if (movie_id && !isNaN(movie_id)) {
      // this.getMovieDetails(movie_id);
      this.getMovieResponse(movie_id);
      this.movie_id = movie_id;
    }

    this.$store.dispatch("master/setLabelMaster", { key: labels.type });
    this.$store.dispatch("master/setLabelMaster", { key: labels.language });
    this.$store.dispatch("master/setLabelMaster", { key: labels.director });
    this.$store.dispatch("master/setLabelMaster", { key: labels.cast });
    this.$store.dispatch("master/setLabelMaster", { key: labels.genre });
    this.$store.dispatch("master/setLabelMaster", { key: labels.country });
    this.$store.dispatch("master/setLabelMaster", { key: labels.age });
    this.$store.dispatch("master/setLabelMaster", { key: labels.image_format });
    this.$store.dispatch("master/setLabelMaster", { key: labels.sound_format });
    this.$store.dispatch("master/setLabelMaster", { key: labels.media_type });
    this.$store.dispatch("master/setLabelMaster", { key: labels.pricing_code });
    this.$store.dispatch("master/setLabelMaster", { key: labels.aspect_ratio });
    this.$store.dispatch("master/setLabelMaster", { key: labels.label });
    this.$store.dispatch("master/setLabelMaster", { key: labels.distributor });
    // Tree Data
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.rights_tree,
      master: labels.rights,
      sub_master: labels.sub_right,
    });
    this.$store.dispatch("master/setLabelTreeMaster", {
      key: labels.territory_tree,
      master: labels.territory,
      sub_master: labels.sub_territory,
    });
  },
};
</script>

<style scoped lang="scss">
.form-rows {
  margin-bottom: 30px;
  padding: 5px;
  border: 1px solid lightcyan;
  background-color: white;
  border-radius: 5px;
}

.add-row {
  display: block;
  margin: auto;
  cursor: pointer;
}

.remove-row {
  display: block;
  /* margin: auto; */
  cursor: pointer;
}

.collapse-group {
  margin-top: 30px;
  margin-bottom: 30px;
}

.bg-color1 {
  padding: 5px;
  background-color: rgba(233, 233, 240, 0.1);
}

.form-rows0 {
  border: 1px solid lightcyan;
  margin-bottom: 15px;
  padding: 5px;
  background-color: white;
}

.form-rows1 {
  border: 1px solid lightgray;
  margin-bottom: 15px;
  padding: 5px;
  background-color: white;
}

.form-rows2 {
  border: 1px solid lightgoldenrodyellow;
  margin-bottom: 15px;
  padding: 5px;
  background-color: white;
}

.form-rows3 {
  border: 1px solid lightskyblue;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows4 {
  border: 1px solid gray;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows5 {
  border: 1px solid lightgreen;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows6 {
  border: 1px solid pink;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows7 {
  border: 1px solid plum;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows8 {
  border: 1px solid lightblue;
  margin-bottom: 15px;
  padding: 5px;
}

.form-rows9 {
  border: 1px solid aliceblue;
  margin-bottom: 15px;
  padding: 5px;
}

.v-select {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  font-weight: 600 !important;
  color: #edefff;
}

.information-box {
  // box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.05);
  // border: 0.5px solid lightgrey;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  // align-items: center;
  // padding: 4px;
}

.information-box p {
  // text-align: center;
  // width: 80%;
}

// new
.details-subheading {
  font-weight: bold;
  margin-right: 0.3rem;
}

.details-heading {
  font-weight: bolder;
}

.log-buttons {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
}

@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
